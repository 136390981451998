@import '../../assets/scss/core/variables';

@media (max-width: 1250px) {
  .hide-on-small {
    display: none;
  }
}

.manage_room_modal-footer {
  margin-top: 0.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .ant-btn-red {
    background-color: $errorColor !important;
  }
}

.ant-color-picker .ant-collapse-header {
  display: none !important;
}

.ant-color-picker-trigger.ant-color-picker-trigger-active {
  border-color: $secondaryColor;
}

.new-form-color-picker {
  width: 100% !important;
  &:hover {
    border-color: $secondaryColor;
  }
  .ant-color-picker-color-block {
    width: 100% !important;
  }
}

.meeting-reference {
  display: flex;
  align-items: center;
  color: $textColor;
  width: max-content;
  margin-bottom: 0.3em;
  pointer-events: none;
}

.meetings-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu {
  background-color: $extraLightgrey !important;
  .ant-badge {
    margin-right: 0.2em;
    .ant-badge-count {
      background-color: transparent;
      color: $defaultTextColor;
      box-shadow: 0 0 0 1px $defaultTextColor;
    }
  }

  .ant-menu-item-selected {
    .ant-badge {
      .ant-badge-count {
        color: $secondaryColor;
        box-shadow: 0 0 0 1px $secondaryColor;
      }
    }
  }
}

.meetings-dropdown-item {
  padding-right: 4rem !important;
}

.ant-popover-content {
  ul {
    padding-left: 1.5rem;
  }
}

.action-more-mobile {
  display: none;
}

.inline-alert {
  .ant-space-item {
    display: flex;
    align-items: center;

    .ant-btn span {
      margin-left: 0.2rem;
      font-size: 1em !important;
      text-decoration: underline;
    }
  }
}

.periodicity-radio {
  width: 7rem !important;
}

.join-meeting-link {
  color: $secondaryColor;
  text-decoration: none;

  &:hover {
    color: $secondaryColorLighten;
    cursor: pointer;
  }
}

td.table-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popover-documents:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

@media (max-width: $l) {
  .action-custom-container {
    display: none !important;
  }
}

@media (max-width: $sm) {
  .action-main-container {
    width: 100% !important;

    .action-more {
      display: none;
    }
  }

  .action-column {
    width: 100% !important;
  }

  .action-more-mobile {
    display: flex;
    gap: 1rem;
  }

  .ant-table-measure-row {
    display: none !important;
  }
}
